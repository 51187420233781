export default {
  tabbar1: "첫 페이지",
  tabbar2: "영화관",
  tabbar3: "선비",
  tabbar4: "호텔",
  tabbar5: "개인",
  login: "로그인",
  userNamePlaceholder: "휴대폰번호를 입력해주세요",
  passwordPlaceholder: "암호를 입력하십시오.",
  forgotPassword: "비밀번호를 잊으셨습니까?",
  noAccount: "계좌번호 없어요?",
  registerNow: "지금 등록",
  register: "등록",
  inviteCodeePlaceholder: "초대코드를 입력해주세요",
  consentAgreement: "나는 이미 계좌 개설 협의의 각 조약을 알고 동의했다",
  highEndZone: "프리미엄 엔터프라이즈",
  EnjoyTheUltimateLuxuryExperience: "최고의 럭셔리 환경",
  airborne: "낙하",
  viewMoreData: "추가 데이터 보기",
  aboutCannonRecommend: "약포 추천",
  current: "현재",
  peopleNowMatch: "사람 온라인 일치",
  accountMembership: "약포 계정 입회 성공",
  highEnd: "하이엔드",
  authentication: "인증",
  videoAuthentication: "비디오 인증",
  tollRange: "요금 구간",
  personHeight: "키",
  bust: "가슴둘레",
  serviceCity: "서비스 도시",
  nationalAirborne: "전국 낙하산",
  askHerOut: "데이트 신청할게요",
  uptodate: "최신",
  play: "재생",
  reservation: "예약",
  beautifulGirl: "미소녀",
  people: "사람",
  requiredPromotion: "진급에 필요한",
  integral: "업 데이트",
  rechargePoints: "충전 포인트",
  redeemPoints: "환불을 신청하다",
  myActivityPoints: "총적분",
  currencySymbol: "포인트",
  basicInformation: "프로파일",
  personalReports: "개인 보고서",
  loginPassword: "로그인 암호",
  paymentPassword: "결제 비밀번호",
  taskLog: "작업 로그",
  Fundingdetails: "기금 세부정보",
  noticeAnnouncement: "시스템 공지",
  onlineService: "온라인 고객 서비스",
  logOut: "로그인 종료",
  residence: "주소",
  reservationNow: "지금 예약",
  photo: "사진",
  video: "비디오",
  personalProfile: "프로필",
  popularRecommendations: "인기 추천",
  switchWindows: "창 전환",
  designatedTime: "기간",
  votingResults: "투표 결과",
  ISSUE: "기일",
  data: "데이터",
  big: "장미",
  small: "백합",
  one: "불꽃놀이",
  two: "풍선",
  currentSelectedNumber: "현재 선택 번호",
  pointsPerOrder: "포인트",
  inTotal: "합계",
  amountMoney: "금액",
  availablePoints: "사용 가능한 포인트",
  submitTo: "제출",
  artilleryEngagement: "약포 활동",
  recharge: "충전",
  Withdrawal: "현금 인출",
  AccountDetails: "계정 세부정보",
  SystemOperation: "시스템 운영",
  payeeName: "수취인 이름",
  bankName: "은행명",
  bankCardNumber: "은행카드 번호",
  bankCardNumberPlaceholder: "은행카드 번호를 입력하십시오.",
  branchName: "지점 이름",
  branchNumber: "지점 번호",
  selectBank: "모든 은행 계좌 정보를 입력하세요.",
  nameOfPayer: "지불자 이름",
  uploadPaymentVoucher: "결제 자격 증명 업로드",
  rechargePrompt:
    "작업을 반복하지 마십시오.미수금이 장기간 지속되면 고객에게 문의하십시오.",
  confirmRecharge: "충전 확인",
  exchangeRecords: "환전 기록",
  whole: "모두",
  limitDescription: "한도액 설명",
  singleTransactionLimit: "단일 한도액",
  minimum: "최소",
  highest: "최고",
  withdrawalFrequency: "현금 인출 횟수",
  dailyHighest: "일일 최고",
  order: "회",
  freeOfHandlingFees: "(수수료 500회 면제)",
  receiptTime: "입금 시간: 보통 입금 시간은 5분 정도, 빠르면 2분 이내에 입금",
  residualIntegral: "남은 포인트",
  individual: "개",
  redeemPointsNow: "바로 환불",
  headSculpture: "프로필 사진",
  fullName: "이름",
  gender: "성별",
  male: "남자",
  female: "여자",
  unknown: "알 수 없음",
  bankCardInformation: "은행 카드 정보",
  have: "있음",
  notHave: "없음",
  modifyRealName: "실명 수정",
  save: "저장",
  fullNamePlaceholder: "이름을 입력하십시오.",
  modifyRealNameRemake:
    "계좌의 안전성을 확보하기 위해서는 본명이 묶인 은행카드의 명의와 같아야 한다.",
  modifyGender: "성별 수정",
  cardNumber: "은행명",
  cardName: "사용자 이름",
  cardID: "은행카드 번호",
  addBankCard: "은행 카드 추가",
  addBankCardRemake:
    "참고: 대형 상업은행에 문의하십시오.변경이 필요한 경우 온라인으로 고객 서비스에 문의하십시오.",
  payCardInfoPlaceholder: "결제 카드 정보를 입력하십시오.",
  addBankCardPrompt:
    "친애하는 사용자, 자금의 안전을 확보하기 위해서, 당신의 실제 이름을 링크하고 인출 비밀번호를 설정하십시오.계정 이름과 이름이 일치하지 않으면 자금을 인출할 수 없습니다.",
  personalReport: "개인 보고서",
  profitPoints: "이익 포인트",
  profiCalculationFormula: "수익 계산 공식: 당첨 포인트 - 퀘스트 포인트",
  taskPoints: "작업 포인트",
  withdrawalPoints: "포인트 현금 인출",
  winningPoints: "당첨 포인트",
  changeLoginPassword: "로그인 암호 수정",
  oldPwd: "이전 암호",
  oldPwdPlaceholder: "이전 암호를 입력하십시오.",
  newPwd: "새 암호",
  newPwdPlaceholder: "새 암호를 입력하십시오.",
  againOldPwdPlaceholder: "새 암호를 다시 입력하십시오.",
  taskRecord: "작업 레코드",
  emptyData: "데이터가 비어 있음",
  betPoints: "주문 포인트",
  orderTime: "주문 시간",
  settlementTime: "결제 시간",
  refreshSuccessful: "새로 고침 성공",
  invalidFunction: "이 기능은 유효하지 않습니다.",
  appointmentSuccessful: "안내원에게 연락하세요",
  completeActivationTask: "활성화 작업을 완료하십시오.",
  checkAgreement: "아래에서 계좌 개설 협의를 선택하십시오.",
  integralError: "포인트 오류",
  selectNumber: "번호를 선택하세요",
  fillInPoints: "포인트를 기입해 주세요",
  insufficientPoints: "포인트 부족, 고객센터에 연락하여 충전하세요",
  contactCollect: "이 작업은 관리자에게 문의하십시오.",
  successfulLotteryDraw: "당첨 성공, 기호:",
  notSet: "설정되지 않음",
  contactService: "비밀번호를 변경하려면 고객센터에 문의하십시오.",
  completeTask: "퀘스트 리스트를 완성한 후 입장하세요",
  setUpBankCard: "은행카드를 설정해 주세요.",
  accountOffline: "계정 오프라인",
  fileTooLarge: "파일이 너무 큽니다.",
  formatRule: "올바른 형식의 이미지를 업로드하십시오.",
  uploadSuccessful: "업로드 성공",
  amountError: "금액 오류",
  setNameToBindBankCard: "이름을 설정한 후 카드를 바인딩하십시오.",
  setWithdrawalPasswordToBindBankCard:
    "현금인출 비밀번호를 설정한 후 은행카드를 묶으세요",
  passwordInconsistency: "암호 불일치",
  complete: "전체 입력",
  changWithdrawalPasswordContactSustomerService:
    "현금 인출 비밀번호가 설정되었습니다. 수정이 필요하면 고객센터에 연락하십시오.",
  activateWatchVideo: "주문 브러싱 작업을 완료한 후에만 영상을 보실 수 있습니다",
  rechargeWatchVideo: "충전 후 동영상을 보십시오",
  pour: "주",
  taskList: "작업 명세서",
  clearOrder: "주문 비우기",
  confirmSub: "제출 확인",
  switchLanguage: "언어 전환",
  Chinese: "简体中文",
  English: "English",
  Japanese: "日本語",
  illustrate: "설명",
  submissionTime: "제출 시간",
  auditTime: "감사 시간",
  oldPayPwd: "기존 결제 비밀번호(0000으로 설정되지 않음)",
  payPwdPl: "결제 비밀번호를 입력하십시오.",
  enterPayPwdPl: "결제 비밀번호를 다시 입력하십시오.",
  setPayPwd: "결제 비밀번호 설정",
  yse: "예",
  creditScore: "평판 포인트",
  sdintroduce:
    "국내 및 해외 여행은 국내 최대 숙박 예약 사이트 중 하나인 롯데여행사를 이용해보세요. 호텔/투어 예약, 항공권, 렌터카, 고속버스 예약, 관광정보까지! 1,000만 건 이상의 호텔 리뷰를 보유한 일본 최대 규모의 호텔 중 하나입니다! 낙천 포인트를 적립하고 사용하세요!",
  Membershipincome: "회원 수입",
  Balance: "잔액",
  Income: "오늘의 수입",
  Totaldeposit: "작업 수",
  Totalwithdrawal: "총 수익",
  GrabOrder: "시작",
  popularRestaurant: "인기 있는 레스토랑",
  Restaurantname: "레스토랑 이름",
  Totalamount: "총 금액",
  Commission: "수수료",
  Ordernumber: "주문 번호",
  Submissiontime: "제출 시간",
  Submit: "제출하다",
  History: "기록",
  All: "전체",
  Pending: "대기 중",
  Completed: "완료",
  Pay: "확인하다",
  reviews: "리뷰",
  MAD: "₩",
  includestaxescharges: "세금 및 수수료 포함",
  Expectedreturn: "예상 수익",
  Detail: "상세",
  freeze: "동결",
  OrderQuantity: "주문 수량",
  loadingtext: "로드 중",
  finishedtext: "더 이상 데이터가 없습니다",
  pullingtext: "아래로 당겨 새로고침하세요",
  loosingtext: "새로고침하려면 손을 떼세요",
  Payremainingtime: "남은 시간 결제",
  trade: "거래",
  Rebate: "할인",
  ForcedTransaction: "강제 거래",
  PromotionRebate: "프로모션 리베이트",
  Subleveltransactionrebate: "하위 거래 리베이트",
  Rules: "규칙 설명",
  confirmButtonText: "확인하다",
  CreditScore: "평판 포인트",
};
