<template>
	<div class="container page">
		<van-nav-bar :title="$t('recharge')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main-box">
			<!-- <div class="label" color="">Sau khi hoàn thành chuyển khoản, vui lòng tải ảnh chụp màn hình chuyển khoản lên.</div> -->
			<van-cell-group>
				<van-field v-model="bankname" label-width="10.2em" :label="$t('payeeName')" readonly
					:placeholder="$t('payeeName')">
					<template #button>
						<!-- <van-button size="large" type="primary" color="linear-gradient(90deg, #e6c3a1, #7e5678)">Sao
							chép</van-button> -->
					</template>
				</van-field>
				<van-field v-model="bank" label-width="10.2em" :label="$t('bankName')" readonly
					:placeholder="$t('bankName')">
					<template #button>
						<!-- <van-button size="large" type="primary" color="linear-gradient(90deg, #e6c3a1, #7e5678)"
							:@onclick="copy()">Sao chép</van-button> -->
					</template>
				</van-field>
				<van-field v-model="banknumber" label-width="10.2em" :label="$t('bankCardNumber')" readonly
					:placeholder="$t('bankCardNumber')">
					<template #button>
						<!-- <van-button size="large" type="primary" color="linear-gradient(90deg, #e6c3a1, #7e5678)">Sao
							chép</van-button> -->
					</template>
				</van-field>
				<van-field v-model="bankfenhang" label-width="10.2em" :label="$t('branchName')"
					:placeholder="$t('branchName')" readonly />
				<van-field v-model="fhnumber" label-width="10.2em" :label="$t('branchNumber')"
					:placeholder="$t('branchNumber')" readonly />
				<van-field v-model="amount" type="number" label-width="10.2em" :label="$t('amountMoney')" placeholder=""
					autofocus='true' />
				<van-field v-model="username" label-width="10.2em" :label="$t('nameOfPayer')"
					:placeholder="$t('nameOfPayer')" />
				<div class="upload">
					<van-uploader @oversize="onOversize" preview-size="200px" :before-read="beforeRead"
						:after-read="clzpAfterRead" preview-image="true" v-model="fileList" :max-count="1"
						:max-size="5 * 1024 * 1024" :upload-text="$t('uploadPaymentVoucher')" />
				</div>
			</van-cell-group>
			<p>{{ $t('rechargePrompt') }}</p>
		</div>
		<van-button class="bindCard" type="default" @click="rechagesubmit()">{{ $t('confirmRecharge') }}</van-button>
	</div>
</template>

<script>
import $ from 'jquery'

export default {
	data() {
		return {
			amount: 0,
			username: '',
			bankInfo: {},
			showBank: false,
			bankid: '',
			bankname: '',
			banknumber: '',
			bank: '',
			fileList: [],
			bankfenhang: "",
			fhnumber: "",
		};
	},
	methods: {
		ajaximg(type, url, params, cb) {
			$.ajax({
				type: type,
				url: url,
				data: params,
				dataType: "json",
				async: true,
				contentType: false,
				processData: false,
				success: (response) => {
					cb(response)
				}
			});
		},
		back() {
			return window.history.back();
		},
		copy() {

		},
		getpayBankInfo() {
			this.$http({
				method: 'get',
				url: 'getpaybankinfo'
			}).then(res => {

				this.bankid = res.data.id;
				this.bankname = res.data.bankname;
				this.bank = res.data.bankhang;
				this.banknumber = res.data.banknumber;
				this.bankfenhang = res.data.bankfenhang;
				this.fhnumber = res.data.fhnumber;
			})

		},
		onOversize(file) {
			console.log(file);
			this.$toast(this.$t('fileTooLarge'));
		},
		//校验图片的格式
		beforeRead(file) {
			if (!/(jpg|jpeg|png|JPG|PNG)/i.test(file.type)) {
				this.$toast(this.$t('formatRule'));
				return false;
			}
			return true;
		},
		//照片上传事件方法
		clzpAfterRead(file) {

			// 上传状态提示开启
			file.status = "uploading";
			file.message = "uploading...";
			console.log(file.file);
			// 创建一个空对象实例
			let fd = new FormData();
			// 调用append()方法添加数据
			fd.append("file", file.file);
			console.log(fd);
			this.ajaximg('post', 'https://api.tmavedio.xyz/api/system/uploadLocality', fd, (res) => {
				console.log(res);

				if (res.code == 200) {
					file.status = "done";
					this.$toast(this.$t('uploadSuccessful'));
					this.clzpfilePath = res.data;
				} else {
					alert(222);
				}
			})


		},
		rechagesubmit() {
			if (!this.bankid) {
				this.$toast(this.$t('bankCardNumberPlaceholder'));
				return true;
			}
			if (!this.bankname) {
				this.$toast(this.$t('bankCardNumberPlaceholder'));
				return true;
			}
			if (!this.banknumber) {
				this.$toast(this.$t('bankCardNumberPlaceholder'));
				return true;
			}
			if (this.amount <= 0) {
				this.$toast(this.$t('amountError'));
				return true;
			}
			if (this.bank === "" || this.bank === null || this.bank === undefined) {
				this.$toast(this.$t('selectBank'));
				return false;
			}
			if (!this.clzpfilePath) {
				this.$toast(this.$t('uploadPaymentVoucher'));
				return false;
			}
			this.$http({
				method: 'post',
				data: {
					bank: this.bank,
					bankname: this.bankname,
					banknumber: this.banknumber,
					amount: this.amount,
					username: this.username,
					bankfenhang: this.bankfenhang,
					fhnumber: this.fhnumber,
					pic: this.fileList[0]['content'],
				},
				url: 'recharge'
			}).then(res => {
				if (res.code === 200) {
					this.$toast(res.msg);
					this.back();
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			})



		}



	},
	created() {
		if (!localStorage.getItem('token')) {
			this.$router.push({
				path: '/Login'
			})
		} else {
			this.getpayBankInfo();
		}
	}
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.upload {

	display: flex;
	justify-content: center;
	margin-top: 30px;
}

.van-cell {
	font-size: 32px;
	line-height: 80px;
}

.van-field__label {
	width: 100px;
}

.van-hairline--bottom::after {
	border-bottom-width: 3px;
}

.bankbox {
	padding: 15px;
	color: #000;
	background-color: #fff;
}

.bankbox .title {
	padding: 8px 10px 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-size: 28px;
}

.main-box {
	background: #fff;

}

.main-box .label {
	padding: 20px;
	font-size: 35px;
	color: #797878;
}

::v-deep .van-picker__toolbar {
	height: 50px;
}

::v-deep .van-picker__cancel,
.van-picker__confirm {
	padding: 0 20px;
	font-size: 20px;
}

::v-deep .van-picker-column {
	font-size: 40px;
}

.main-box p {
	padding: 0 20px;
	font-size: 30px;
	color: #ee0a24;
}

.bindCard {
	margin: 20px 30px 0;
	height: 80px;
	line-height: 1.22667rem;
	border-radius: 50px;
	color: #fff;
	font-size: 30px;
	font-weight: bolder;
	border: none;
	// background: linear-gradient(90deg, #e6c3a1, #7e5678);
	background: linear-gradient(90deg, #6529c9, #cc2996);
}

.upload {
	display: flex;
	justify-content: center;
	margin-top: 30px;

}
</style>