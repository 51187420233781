<template>
  <div class="container page Shuadan">
    <van-nav-bar title="" class="nav-bar">
      <template #right>
        <img src="../../assets/order-fill.png" alt="" style="width: 25px; height: 25px; margin-right: 10px;"
          @click="toOrder">
        <van-icon name="chat-o" size="24" color="#fff" @click="toService" />
      </template>
    </van-nav-bar>
    <div class="shuadanTop">
      <div class="Introduction">
        <div>
          <van-swipe :autoplay="3000" indicator-color="white" height="200">
            <van-swipe-item v-for="(v, key) in banners" :key="key">
              <van-image :src="v.goods_pic" :height="200" width="100%" fit="cover">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
                <template v-slot:error><van-image src="/img/error.png" /></template>
              </van-image>
            </van-swipe-item>
          </van-swipe>
        </div>
        <div
          style="position: absolute; bottom: 0; width: 100%; background: rgba(0,0,0,0.2); font-size: 11px; padding: 10px; border-radius: 10px 10px 0 0;">
          {{ notice }}
        </div>
      </div>
      <div class="Income">
        <div>
          <div @click="showType = 1">{{ $t('Membershipincome') }}</div>
          <div @click="showType = 2">{{ $t('Rules') }}<van-icon name="info-o" size="18" /></div>
        </div>
        <div class="IncomeMain" v-show="showType == 1">
          <div>
            <p>{{ $t('Balance') }}</p>
            <p>{{ income.balance || 0 }}</p>
          </div>
          <div>
            <p>{{ $t('Income') }}</p>
            <p>{{ income.day_deal || 0 }}</p>
          </div>
          <div>
            <p>{{ $t('Totaldeposit') }}</p>
            <p>{{ income.totay_order_finish_num || 0 }} / {{ income.order_incomplete_num || 0 }}</p>
          </div>
          <div>
            <p>{{ $t('Totalwithdrawal') }}</p>
            <p>{{ income.total_deal || 0 }}</p>
          </div>
        </div>
        <div class="IncomeRules" v-show="showType == 2">{{ rules }}</div>
        <div class="GrabOrder" @click="submitOrder">{{ $t('GrabOrder') }}</div>
      </div>
    </div>
    <div class="shuadanBtm">
      <!-- <div class="RechargeTab">
        <div :class="{ active: active == 0 }" @click="RechargeTabFn(0)">
          <img src="../../assets/Rechargelist.png" alt="" style="width: 25px; height: 25px;">
          <span>Recharge list</span>
        </div>
        <div :class="{ active: active == 1 }" @click="RechargeTabFn(1)">
          <img src="../../assets/Latestrecharge.png" alt="" style="width: 25px; height: 25px;">
          <span>Latest recharge</span>
        </div>
      </div>
      <div class="Rechargelist">
        <div v-for="i of 10" :key="i">
          <img src="../../assets/avatar.png" alt="" style="width: 40px; height: 40px;">
          <div>
            <div>congratulations******9397</div>
            <div>Benefts956Receipt</div>
          </div>
        </div>
      </div> -->
      <div>{{ $t('popularRestaurant') }}</div>
      <div class="Rechargelist">
        <div v-for="(v, i) in goodslist" :key="i" class="item">
          <div>
            <img v-if="i % 2 == 0 && v[0]" class="firstimg" :src="v[0] ? v[0].goods_pic : ''" alt=""
              @click="toDetail(v[0] || {})">
            <div>
              <img v-if="v[1]" :src="v[1] ? v[1].goods_pic : ''" alt="" @click="toDetail(v[1] || {})">
              <img v-if="v[2]" :src="v[2] ? v[2].goods_pic : ''" alt="" @click="toDetail(v[2] || {})">
            </div>
            <img v-if="i % 2 != 0 && v[0]" class="firstimg" :src="v[0] ? v[0].goods_pic : ''" alt=""
              @click="toDetail(v[0] || {})">
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showQD" :style="{ width: '90%', height: '70%' }" :lock-scroll="false">
      <img :src="hotelInfo.goods_pic" alt="" class="QDimg">
      <div class="QDbox">
        <div>{{ $t('Restaurantname') }}: {{ hotelInfo.goods_name }}</div>
        <div>{{ $t('Totalamount') }}: {{ hotelInfo.num }} {{ $t('MAD') }}</div>
        <div>{{ $t('OrderQuantity') }}: {{ hotelInfo.goods_count }}</div>
        <div>{{ $t('Expectedreturn') }}: {{ hotelInfo.commission }} {{ $t('MAD') }}</div>
        <div>{{ $t('Ordernumber') }}: {{ hotelInfo.oid }}</div>
        <div>{{ $t('Submissiontime') }}: {{ hotelInfo.addtime }}</div>
      </div>
      <div class="GrabOrder" style="border-radius: 8px 8px 0 0;" @click="SubmitForm">{{ $t('Submit') }}</div>
    </van-popup>
    <van-dialog width="80%" v-model="showKF" confirmButtonColor="#8d47ef" :confirmButtonText="$t('confirmButtonText')"
      :message="kfmessage" @confirm="toService"></van-dialog>
  </div>
</template>

<script>
import config from "../../http/config";
export default {
  data() {
    return {
      config,
      active: 0,
      showQD: false,
      banners: [],
      notice: "",
      rules: "",
      income: {},
      hotelInfo: {},
      goodslist: [],
      showType: 1,
      showKF: false,
      kfmessage: "",
    };
  },
  created() {
    if (localStorage.getItem('token')) {
      this.init();
    } else {
      this.$router.push({ path: '/Login' });
    }
  },
  destroyed() { },
  methods: {
    init() {
      // 酒店规则
      this.$httpNew({
        method: 'get',
        data: {
          id: 8,
        },
        url: 'get_notice'
      }).then(res => {
        if (res.code == 0) {
          this.rules = res.data.content.replace(/<[^>]*>/g, '');
        } else {
          this.rules = "";
        }
      })
      // 简介
      this.$httpNew({
        method: 'get',
        data: {
          id: 11,
        },
        url: 'get_notice'
      }).then(res => {
        if (res.code == 0) {
          this.notice = res.data.content.replace(/<[^>]*>/g, '');
        } else {
          this.notice = "";
        }
      })
      // 会员收入
      this.$httpNew({
        method: 'post',
        data: {
          user_id: localStorage.getItem("token"),
        },
        url: 'getIncome'
      }).then(res => {
        if (res.code == 0) {
          this.income = res.data || {};
        } else {
          this.income = {};
        }
      })
      // 酒店列表
      this.$httpNew({
        method: 'post',
        data: {},
        url: 'goodslist'
      }).then(res => {
        if (res.code == 0) {
          this.goodslist = this.reorganizationArr(res.data || []);
          this.banners = this.goodslist[0];
        } else {
          this.goodslist = [];
          this.banners = [];
        }
      })
    },
    toService() {
      let tel = this.$store.getters.getUserInfo.tel;
      let kefu = this.$store.getters.getBaseInfo.kefu.replace(/\s*/g, "");
      let metadata = `&metadata={"address":"111","age":"18","comment":"111","email":"123@qq.com","gender":"男","name":"${tel}","qq":"123", "tel":"${tel}","weibo":"111","weixin":"111"}`;
      return window.location.href = kefu + metadata;
    },
    RechargeTabFn(v) {
      this.active = v;
    },
    submitOrder() {
      // 抢单酒店信息
      this.$toast.loading({
        message: this.$t('loadingtext'),
        forbidClick: true,
      });
      this.$httpNew({
        method: 'post',
        data: {
          user_id: localStorage.getItem("token"),
          cid: 1,
          reCAPTCHA: "",
          v: 3,
          m: Math.random(),
          real: 0,
        },
        url: 'submit_order'
      }).then(res => {
        if (res.code == 0) {
          this.$httpNew({
            method: 'post',
            data: {
              id: res.oid,
              user_id: localStorage.getItem("token"),
            },
            url: 'order_info'
          }).then(res => {
            this.$toast.clear();
            if (res.code == 0) {
              this.hotelInfo = res.data || {};
              this.showQD = true;
            } else {
              this.$toast(res.info);
            }
          })
        } else {
          this.$toast(res.info);
        }
      })
    },
    SubmitForm() {
      this.$toast.loading({
        message: this.$t('loadingtext'),
        forbidClick: true,
      });
      this.$httpNew({
        method: 'post',
        data: {
          oid: this.hotelInfo.oid,
          user_id: localStorage.getItem("token"),
        },
        url: 'do_order'
      }).then(res => {
        this.$toast.clear();
        if (res.code == 0) {
          this.$toast(res.info);
          this.showQD = false;
          // this.$router.push({ path: '/fakeTransactionsList', query: { active: 3 } });
          this.init();
        } else if (res.code == 2) {
          // 余额不足，弹窗提示前往客服
          this.kfmessage = res.info;
          this.showKF = true;
        } else {
          this.$toast(res.info);
        }
      })
    },
    toDetail(v) {
      this.$router.push({ path: '/fakeTransactionsDetail', query: { id: v.id } });
    },
    toOrder() {
      this.$router.push({ path: '/fakeTransactionsList' })
    },
    reorganizationArr(data) {
      var result = [];
      for (var i = 0; i < data.length; i += 3) {
        result.push(data.slice(i, i + 3));
      }
      return result;
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.Shuadan {
  background: url('../../assets/shuadan_bg.jpg') no-repeat top center;
  background-size: cover;
  overflow: auto;
  padding-bottom: 120px;
}

.nav-bar {
  .right {
    padding: 0 8px;
  }
}

.shuadanTop {
  padding: 5px 5px 0;

  .Introduction {
    // padding: 15px;
    // background: #df35ad;
    // border-radius: 10px;
    margin-bottom: 5px;
    color: #fff;
    position: relative;
  }

  .Income {
    padding: 15px;
    background: linear-gradient(90deg, #df35ad, #4f1db5);
    color: #fff;
    border-radius: 10px;

    >div:nth-child(1) {
      border-bottom: 1px solid #fff;
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;

      >div:nth-child(2) {
        display: flex;
        align-items: center;

        .van-icon {
          margin: 2px 0 0 5px;
        }
      }
    }

    .IncomeMain {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      text-align: center;

      >div {
        flex: 0 0 50%;

        p {
          margin: 10px 0 0;
        }

        >p:nth-child(1) {
          font-weight: 700;
        }
      }
    }

    .IncomeRules {
      padding: 15px 15px 0;
    }
  }
}

.shuadanBtm {
  padding: 15px;
  // background: rgba(255, 255, 255, 0.2);

  >div:nth-child(1) {
    color: #4f1db5;
    text-align: center;
    font-weight: bold;
  }

  .Rechargelist {
    .item {
      >div {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        >div {
          width: 49%;
        }

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          margin-top: 10px;
          display: block;
          border-radius: 8px;
        }

        .firstimg {
          width: 49%;
          height: 420px;
        }
      }
    }

    // >div {
    //   display: flex;
    //   align-items: center;
    //   padding: 20px 0;

    //   >div {
    //     flex: 0 0 calc(100% - 50px);
    //     padding-left: 20px;

    //     >div:nth-child(1) {
    //       font-weight: 700;
    //       margin-bottom: 10px;
    //     }

    //     >div:nth-child(2) {
    //       font-size: 14px;
    //     }
    //   }
    // }
  }

  // .RechargeTab {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   text-align: center;

  //   >div {
  //     flex: 0 0 50%;
  //     background: rgba(255, 255, 255, 0.2);
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     padding: 10px 0;

  //     img {
  //       margin-right: 10px;
  //     }
  //   }

  //   >div.active {
  //     background: rgba(0, 0, 0, 0.2);
  //   }
  // }
}

.QDimg {
  width: 100%;
  height: 500px;
  margin-right: 10px;
  display: block;
  object-fit: cover;
  margin: 0;
}

.QDbox {
  padding: 15px;

  >div {
    padding: 5px 0;
    font-size: 14px;
    box-sizing: border-box;
  }
}

.GrabOrder {
  background: linear-gradient(rgb(254, 89, 134), rgb(255, 132, 139));
  color: #fff;
  border-radius: 8px;
  padding: 10px 0;
  text-align: center;
  font-size: 32px;
  margin-top: 20px;
  line-height: 50px;
}

::v-deep .van-dialog__footer {
  padding: 10px 0;
}

::v-deep .van-dialog__message {
  padding: 20px;
}
</style>
